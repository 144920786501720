@charset "utf-8";

@import 'mixin';
@import 'variable';
@import 'font';


/* RESET CSS*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ul, ol, li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
img {
	max-width: 100%;
	vertical-align: bottom;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.h1, .h2, .h3, h1, h2, h3 {
	margin: 0;
	padding: 0;
}
.h4, .h5, .h6, h4, h5, h6 {
	margin: 0;
	padding: 0;
}
a {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}
.bold {
	font-weight: bold;
}
.bg-set {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

/* OPACITY & HOVER UNDERLINE */
.hvO {
	@include transition(0.15s ease opacity);
	&:hover {
		@include opacity(0.7);
	}
}
.hvU {
	&:hover {
		text-decoration: underline;
	}
}

%no-select {
	-webkit-touch-callout: none; // iOS Safari
	-webkit-user-select: none; // Safari
	-khtml-user-select: none; // Konqueror HTML
	-moz-user-select: none; // Old versions of Firefox
	-ms-user-select: none; // Internet Explorer/Edge
	user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox 
}

/* CONTAINER */
.bigCont {
	max-width: 1200px;
	width: 100%;
	margin: auto;
	@media screen and (max-width: 1250px) {
		padding: 0 4%;
	}
}
.cont {
	max-width: 976px;
	width: 100%;
	margin: auto;

	@media (max-width: 1200px) {
		max-width: 90%;
	}
}
.cont1 {
	max-width: 83.021%;
	width: 100%;
	margin: auto;
	
	@media (max-width: 767px) {
		max-width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}
}
/* SECTION */
.sectionPage {
	padding: 60px 0;
	@media screen and (max-width: 480px) {
		padding: 40px 0;
	}
}

/* Float */
.hvr-float {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
  }
  .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
  }

/* Sink */
.hvr-sink {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.hvr-sink:hover, .hvr-sink:focus, .hvr-sink:active {
-webkit-transform: translateY(5px);
transform: translateY(5px);
}

  /* Icon Down */
@-webkit-keyframes hvr-icon-down {
	0%,
	  50%,
	  100% {
	  -webkit-transform: translateY(0);
	  transform: translateY(0);
	}
	25%,
	  75% {
	  -webkit-transform: translateY(6px);
	  transform: translateY(6px);
	}
  }
  @keyframes hvr-icon-down {
	0%,
	  50%,
	  100% {
	  -webkit-transform: translateY(0);
	  transform: translateY(0);
	}
	25%,
	  75% {
	  -webkit-transform: translateY(6px);
	  transform: translateY(6px);
	}
  }
  /* Icon Down */
  .hvr-icon-down {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-icon-down .hvr-icon {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
  }
  .hvr-icon-down:hover .hvr-icon, .hvr-icon-down:focus .hvr-icon, .hvr-icon-down:active .hvr-icon {
	-webkit-animation-name: hvr-icon-down;
	animation-name: hvr-icon-down;
	-webkit-animation-duration: 0.75s;
	animation-duration: 0.75s;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
  }

  .hvr-rectangle-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #e1e1e1;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
  }
  .hvr-rectangle-out:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #2098D1;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
  }
  .hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
	color: white;
  }
  .hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
	-webkit-transform: scale(1);
	transform: scale(1);
  }

@font-face {
    font-family: 'Glacial';
    src: url("../fonts/GlacialIndifference-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Glacial';
    font-weight: bold;
    src: url("../fonts/GlacialIndifference-Bold.otf") format("opentype");
}

.btn-register-wa {
	width: 175px;
	height: 45px;
	border: 1px solid $wa;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $wa;
	background-color: $white;
	font-size: 18px;
	font-weight: normal;
	border-radius: 5px;
	position: relative;
	transition: $animate;
	@media screen and (max-width: 767px) {
		margin: auto;
	}
	@media screen and (max-width: 560px) {
		width: 150px;
		height: 40px;
	}
	svg {
		width: 18px;
		height: 18px;
		position: relative;
		@media screen and (max-width: 560px) {
			width: 16px;
			height: 16px;
		}
	}
	span {
		font-weight: normal;
		font-size: 18px;
		color: $wa;
		margin-left: 10px;
		position: relative;
		transition: $animate;
		@media screen and (max-width: 560px) {
			font-size: 16px;
		}
	}
	&:hover {
		background-color: $wa;
		svg {
			path {
				fill: $white;
			}
		}
		span {
			color: $white;
		}
	}
}

.btn-learning,
.btn-register {
	width: 150px;
	height: 45px;
	border: 1px solid $red;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $red;
	background-color: transparent;
	font-size: 16px;
	font-weight: normal;
	border-radius: 5px;
	position: relative;
	transition: $animate;
	@media screen and (max-width: 767px) {
		margin: auto;
	}
	@media screen and (max-width: 560px) {
		width: 150px;
		height: 40px;
	}
	svg {
		width: 18px;
		height: 18px;
		position: relative;
		@media screen and (max-width: 560px) {
			width: 16px;
			height: 16px;
		}
	}
	span {
		font-weight: normal;
		font-size: 16px;
		color: $red;
		// margin-left: 10px;
		position: relative;
		transition: $animate;
		@media screen and (max-width: 560px) {
			font-size: 16px;
		}
	}
	&:hover {
		background-color: $red;
		svg {
			path {
				fill: #FFF;
			}
		}
		span {
			color: #FFF;
		}
	}
}

.title-section {
	text-align: left;
	font-weight: bold;
	color: $color-brand;
	font-size: 42px;
	margin-bottom: 82px;
	@media screen and (max-width: 1200px) {
		margin-bottom: 40px;
		font-size: 36px;
	}
	@media screen and (max-width: 767px) {
		font-size: 32px;
	}
	@media screen and (max-width: 560px) {
		font-size: 24px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 370px) {
		font-size: 22px;
	}
	@media screen and (max-width: 350px) {
		font-size: 22px;
		margin-bottom: 30px;
	}
}

/* Grow Shadow */
.hvr-grow-shadow {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
  }
  .hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }







