@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

* {
    text-decoration: none !important;
}

.btnFixed {
    position: absolute;
}

#header {
    height: 100px;
    border-top-width: 5px;
    &.fixed {
        position: unset;
    }
    .inner {
        padding-left: 15px;
        padding-right: 15px;
    
        .leftSide {

            width: 200px;
            .logo {
                p {
                    font-size: 16px;
                }
            }
        }

        .rightSide {
            .k-meal {
                width: 150px;
                margin-right: 30px;
            }
            nav {
                li {
                    margin-left: 30px;
                    a {
                        font-size: 12px;
                        padding-bottom: 5px;
                        img {
                            &.nav1 {
                                height: 32px;
                            }
                            &.nav2 {
                                height: 39px;
                            }
                            &.nav3 {
                                height: 44px;
                            }
                            &.nav4 {
                                height: 35px;
                            }
                            &.nav5 {
                                height: 24px;
                            }
                        }
                        span {
                            margin-top: 10px;
                            height: 31px;
                            word-break: keep-all;
                        }
                    }
                    &:last-child {
                        span {
                            margin-top: 15px;
                        }
                    }
                }
            }
            .telp {
                margin-left: 30px;
                width: 220px;
                p {
                    font-size: 11px;
                }
            }
        }
    }
}

.cont {
    max-width: 90%;
}

#mainvisual {
    margin-top: 0;
    .catchcopy {
        img {
            width: 50%;
        }
    }
}

#main {
    &.topPage {
        #rss {
            .notice {
                max-width: 750px;
            }
            .event {
                padding: 0;
                .item {
                    .title-top {
                        align-items: center;
                        h2 {
                            font-weight: bold;
                        }
                    }
                    ul {
                        li {
                            a {
                                padding: 40px;
                                .imgSide {
                                    height: 300px;
                                }
                            }
                        }
                    }
                }
            }
            .latest {
                margin-top: 60px;
                .swiper-wrapper {
                    .swiper-slide {
                        width: 225px !important;
                        height: 260px;
                        padding: 15px;
                        padding-bottom: 10px;
                        .img {
                            height: 140px;
                        }
                    }
                }
            }
        }

        #message {
            padding: 60px 0 100px;
            .inner {
                .titleSection {
                    width: 530px;
                    height: 122px;
                    font-size: 50px;
                }
                .content {
                    @include align-items(center);
                    .img {
                        width: 250px;
                        margin-right: 60px;
                    }
                    .text {
                        flex: 1;
                        width: auto;
                        padding: 30px;
                        top: 0;
                        @include border-radius(15px);
                        img {
                            right: -30px;
                            top: -60px;
                            width: 250px;
                        }
                        .title {
                            font-size: 28px;
                            margin-bottom: 20px;
                        }
                        .desc {
                            padding-left: 20px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        #bento {
            padding: 60px 0 100px;
            .titleSec {
                margin-bottom: 30px;
                h2 {
                    font-size: 60px;
                    span {
                        font-size: 45px;
                    }
                }
                img {
                    width: 500px;
                    top: -20px;
                }
            }
            .inner {
                padding: 30px;
                padding-bottom: 100px;
                @include border-radius(15px);
                border-width: 3px;
                .left {
                    padding: 30px;
                    h2 {
                        font-size: 36px;
                        margin-bottom: 20px;
                    }
                    .desc {
                        margin-bottom: 30px;
                        padding-left: 20px;
                        font-size: 16px;
                    }
                    a {
                        position: relative;
                        bottom: auto;
                        left: auto;
                        width: 100%;
                        height: 50px;
                        @include border-radius(10px);
                        span {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        #about {
            padding: 40px 0 60px;
            .k-meal {
                padding: 30px;
                margin-bottom: 30px;
                .left,
                .right {
                    width: 27.5%;
                }
                .center {
                    .red {
                        p {
                            font-size: 24px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                    h3 {
                        font-size: 28px;
                        line-height: 1.5;
                        font-weight: bold;
                    }
                    h1 {
                        margin: 10px 0 15px;
                        img {
                            width: 230px;
                        }
                    }
                    .desc {
                        font-size: 14px;
                    }
                }
            }
            .profile {
                margin-bottom: 30px;
                border-width: 3px;
                @include border-radius(15px);
                padding: 30px 30px 80px;
                h2 {
                    font-size: 32px;
                    margin-bottom: 30px;
                }
                div {
                    table {
                        tr {
                            th {
                                width: 275px;
                                font-size: 18px;
                                padding: 15px;
                            }
                            td {
                                font-size: 18px;
                                padding: 15px 0;
                                padding-right: 15px;
                                img {
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .contactBtn {
                a {
                    padding: 15px;
                    margin: 0 15px;
                    height: 130px;
                    @include border-radius(15px);
                    padding-right: 15px;
                    &.contact {
                        .icon {
                            width: 40px;
                            font-size: 50px;
                        }
                        .text {
                            img {
                                width: 85%;
                            }
                            p {
                                font-size: 20px;
                                margin-bottom: 15px;
                            }
                            span {
                                font-size: 20px;
                                margin-top: 15px;
                            }
                        }
                    }
                    &.message {
                        @include justify-content(center);
                        .icon {
                            width: 50px;
                            font-size: 45px;
                            margin-right: 20px;
                        }
                        .text {
                            @include flex(unset);
                            p {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }

    }
}

.pageTop {
    position: absolute;
}
